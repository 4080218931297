export default {
welcome_to_profiler: "<b>Notino Fragrance Finder</b>",
what_are_you_looking_for: "Pomożemy Ci w wyborze perfum, które będą Tobie lub Twoim bliskim pasować jak ulał.<br> Komu chcesz podarować idealnie dobrane perfumy?",
build_your_profile_and: "Pomożemy Ci w wyborze perfum, które będą Tobie lub Twoim bliskim pasować jak ulał.<br> Komu chcesz podarować idealnie dobrane perfumy?",
search_by: "Polecimy Ci perfumy zgodne z Twoimi preferencjami",
gender_m: "Mężczyzna",
gender_f: "Kobieta",
mode_perfumes: "Ulubione perfumy",
mode_ingredients: "Nuty zapachowe",
back: "Powrót",
novelty: "Nowość",
go_back_to_finder: "Wróć do Fragrance Finder",
done: "Gotowe",
best_sellers: "Bestseller",
from: "od",
search_your_perfume: "<b>Wybierz<b> do 5 różnych perfum, które lubisz Ty lub osoba, dla której wybierasz perfumy.",
search_your_perfume_flat: "Zacznij wpisywać nazwę swoich ulubionych perfum lub markę",
select_your_perfume: "<b>Wybierz</b> swoje ulubione perfumy.",
no_perfumes_found: "Nie znaleziono żadnych perfum :(",
no_ingredients_found: "Nie znaleziono żadnych nut zapachowych :(",
something_went_wrong: "Niestety, nie udało nam się znaleźć żadnych wyników. Wprowadź nową nazwę",
select_fav_ings: "<b>Wybierz</b> swoje ulubione nuty zapachowe, a my pomożemy Ci znaleźć najlepsze perfumy.",
search_fav_ings: "Wpisz nazwę swoich ulubionych nut zapachowych.",
building_your_profile: "<b>Tworzymy</b> Twój<br> profil zapachowy",
edit_your_profile: "Edytuj profil",
fragrances: "Perfumy",
ingredients: "Nuty zapachowe",
concepts: "Propozycje",
next_step: "Gotowe",
what_gender: "Dla kogo przeznaczony jest ten profil?",
selected_ingredients: "Twoje ulubione nuty zapachowe",
selected_perfumes: "Twoje ulubione perfumy",
no_perfumes_yet: "W Twoim profilu nie ma żadnych perfum",
no_ingredients_yet: "W Twoim profilu nie ma żadnych nut zapachowych",
no_conceptss_yet: "W Twoim profilu nie ma żadnych propozycji",
get_recomendations: "Uzyskaj rekomendacje",
this_are_your_ings: "To są Twoje ulubione nuty zapachowe",
your_profile_to_edit: "Twój profil",
add_more_fragrances: "Dodaj więcej perfum",
add_more_ingredients: "Dodaj więcej nut zapachowych",
add_more_concepts: "Dodaj więcej propozycji",
top_6_recos: "PERFUMY NA MIARĘ",
recos_based_on: "Rekomendacje bazują na informacjach pozyskiwanych z Twojego profilu zapachowego.",
rest_of_recos: "Inne rekomendacje",
view_details: "Zobacz szczegóły",
your_profile_is_empty: "Twój profil jest pusty!",
add_to_profile_to_get_recos: "Dodaj perfumy lub nuty zapachowe do swojego profilu i uzyskaj rekomendacje",
price: "cena",
buy_it_now: "Dowiedz się więcej",
prominent_ings: "Główne nuty zapachowe",
all_ings: "Pełna lista nut zapachowych",
why_do_we_recommend: "Dlaczego polecamy <b>te perfumy</b>?",
other_ings_present: "Podobne, ale o mniejszej intensywności",
description: "Lista",
evaluate_this_reco: "Oceń tę rekomendację",
how_relevant_do_you: "Jak bardzo trafna według Ciebie jest <b>ta rekomendacja</b>?",
submit: "Prześlij",
check_your_new_recommendations_1: "<b>Profil zaktualizowany.</b> Dziękujemy!",
check_your_new_recommendations_2: "Masz nowe rekomendacje.",
view_new_recommendations: "Zobacz nowe rekomendacje",
close: "zamknij",
loading: "Ładowanie",

//Profile_desc,
you_love_perfumes_of_the: "<b>lubisz perfumy</b><br>",
you_love_perfumes_of_the_plural: "<b>lubisz perfumy</b><br>",
and: "i",
desc_family: "rodzina zapachów",
desc_families: "rodziny zapachów",
you_also_love_plural: "lubisz też podgrupy zapachowe",
you_also_love: "lubisz też podgrupę zapachową",
desc_sub_family: "podgrupa zapachowa",
desc_sub_families: "podgrupy zapachowe",

//RECOS:,
it: "to",
also: "również",
$pronoun_self: "Ty",
$possessive_singular_self: "Twoje",
$possessive_refl_singular_self: "moje",
$possessive_plural_self: "Twoje",
$possessive_refl_plural_self: "moje",
$possessive_singular_prep_self: "Twoje",
$possessive_singular_liste_self: "Twoje",
$you_self: "Ty",
$you_prep_self: "Ty",
$do_you_have_self: "masz",
$that_you_love_self: "które lubisz",
$you_love_self: "lubisz",
$you_adore_self: "lubisz",
$apreciate_self: "podoba Ci się",
$you_have_self: "masz",
reco_desc_title: "Polecamy te perfumy, ponieważ",
possible_reco_title: "Może $you_love te perfumy, ponieważ",
recommended_by_hero: "$you_adore ich główną nutę zapachową: <b>%HERO%</b>",
recommended_by_fam: "należy do jednej z $possessive_plural ulubionych rodzin zapachów <b>%FAMILY%</b>",
not_recommended_by_fam: "Mimo że to inny profil zapachowy niż $posessive_singular_prep, $you_prep zwróć uwagę na",
recommended_by_ings: "w kompozycji znajdziesz kilka $possessive_plural ulubionych nut zapachowych: ",
furthermore: "Dalej",
home: "Nowe propozycje",
my_profile: "Mój profil",
no_concepts_yet: "W Twoim profilu nie ma żadnych propozycji",
view_more: "Pokaż więcej",
select_fav_ingredients_to_find_perfumes: "Wybierz ulubione <b>nuty zapachowe</b><br> i znajdź swoje <br> ulubione <b>perfumy</b>",
your_prefered_concepts: "Cechy Twoich ulubionych perfum",
concept_fresh: "Świeże (np. cytrusowe)",
concept_intense: "Intensywne (np. drzewne)",
concept_casual: "Na co dzień (np. do pracy)",
concept_special_occasion: "Na specjalne okazje (np. na randkę)",
concept_sure_bet: "Uniwersalne",
concept_novelty: "Modne (np. aktualne trendy)",
change_concepts: "Zmiana wybranych preferencji",
select_concepts: "Już prawie koniec",
add_concepts: "Dodaj więcej cech ulubionych perfum",
or: "lub",
explore_by: "Wybierz wg kryterium",
brands: "Marki",
your_selection: "Twój wybór",
list_view: "Lista ulubionych perfum",
graphic_view: "Twój profil zapachowy",
recos_based_on_selection: "Rekomendacje w oparciu o Twoje preferencje",
add_to_profile: "Dodaj do mojego profilu",
do_you_want_to_reset: "Chcesz ponownie skonfigurować swój profil?",
start_new: "Zacznij od nowa",
keep_perfumes: "Zapisz perfumy",
click_to_view: "Kliknij, aby zobaczyć",
already_in_profile: "Już dodano",
only_showing_that_combine_with: "Zobacz jedynie nuty zapachowe w połączeniu z",
see_all_ings_in_this_fam: "Zobacz wszystkie nuty z tej rodziny zapachowej",
select_concepts_subtitle: "Aby otrzymać rekomendacje, musisz wybrać co najmniej 2 perfumy (kliknij wstecz) lub dodać co najmniej jedną z poniższych cech zapachu.",
action: "w promocji",
free_del: "bezpłatna wysyłka",
new_in: "nowość",
cancel: "anuluj",
see_more: "Zobacz więcej",
see_less: "Zobacz mniej",
recommendation_by: "Zalecenia z tym składnikiem",
apply: "Zastosuj",
modal_limit_ingredients_title: "Maksymalna ilość składników",
modal_limit_ingredients_text: "Osiągnąłeś maksymalny limit wyboru składników (4). Usuń jeden składnik przed dodaniem kolejnego.",
empty_selection: "Nie wybrano żadnych opcji",
empty_selection_text: "Wybierz jeden lub więcej zapachów do rekomendacji",
search_perfume_subtitle: "Świetnie! Masz teraz dostęp do spersonalizowanych rekomendacji.",
perfume_search_subtitle: "Świetnie! Masz teraz dostęp do spersonalizowanych rekomendacji.",
filter_by_brand: "Filtrowanie wg marki",
perfume_list: "Wybierz zapach",
others: "",
some_error: "Ups! Wygląda na to, że na stronie wystąpił błąd. Pracujemy nad tym, aby jak najszybciej to naprawić. Dziękujemy za cierpliwość!",
no_brands_found: "Nie znaleziono żadnych marek :("
}